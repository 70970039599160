import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function TermsPage() {
  return (
    <Layout>
      <SEO title="Terms & Conditions" />
      <section className="md:p-0 text-left mt-28 md:mt-24">
        <div className="px-6 md:max-w-7xl mx-auto">
            <h1 className="inline-block mb-4 text-5xl md:text-6xl font-bold text-black font-heading">
            Terms & Conditions
            </h1>
            <div className="md:max-w-3xl">
                <h2 className="text-3xl md:text-4xl text-black font-heading py-3">1. Introduction</h2>
                <p>These are the general terms of the relationship between
                    <ul className="list-disc ml-4 pb-5">
                        <li>
                            you {"-"} the website visitor or user of our platform
                        </li>
                        <li>
                        and us {"-"} Nubee (Pty) Ltd (Company number: xx) also known as Nubee {"-"} the website and platform owner.
                        </li>
                    </ul>
                    They cover any use of our website or platform. You agree to be legally bound by the terms by visiting and using our website or platform. Please do not use the website or platform if you do not agree to the terms.
                </p>
            </div>
            <div className="md:max-w-3xl pt-10">
                <h2 className="text-3xl md:text-4xl text-black font-heading py-3">2. Definitions and interpretation</h2>
                <h3 className="font-bold">2.1 Definitions. In the agreement:</h3>
                <p>terms means the terms, consisting of: 
                    <ul className="list-disc ml-4 pb-5">
                        <li>
                            these terms of use; and
                        </li>
                        <li>
                            any other relevant specific terms, policies, disclaimers, rules, and notices agreed to between the parties, (including any that may be applicable to a specific section or module of the website)
                        </li>
                    </ul>
                    we, us, or our means our organisation, the owner of the website and includes our officers, agents, employees, owners, co-branders, and associates where the terms limit or exclude our liability. <br></br>
                    you or your means any visitor or user of this website, including any other person, website, business, or agent (including any virtual or robotic agent) associated with the visitor.
                </p>
                <h3 className="pt-5 font-bold">2.2 Interpretation.</h3>
                <p>If the meaning of any general terms conflicts with any other relevant specific terms, the specific terms will apply. Specific terms, such as our terms of service, privacy policy, or security policy apply to specific sections of the website or have been specifically agreed between you and us.<br></br>
                Platform. means our web and mobile application that offers learner drivers learning content and connects them with driving instructors.
                </p>
            </div>
            <div className="md:max-w-3xl pt-10">
                <h2 className="text-3xl md:text-4xl text-black font-heading py-3">2. Definitions and interpretation</h2>
                <h3 className="font-bold">3.1 Licence.</h3>
                <p>We grant you a limited licence to use our website and platform on these terms. We may cancel your licence at any time for any reason. Your licence is automatically cancelled if you do not get our written permission before using this website in a way these terms do not allow.</p>
                <h3 className="pt-5 font-bold">3.2 Breach.</h3>
                <p> If you breach any of the terms or infringe any other {"person's"} rights (including copyright), we may cancel your licence, block you from using the website, claim specific performance or damages against you, and take any other steps the law allows, without affecting our rights.</p>
                <h3 className="pt-5 font-bold">3.3 Framing and linking.</h3>
                <p> You may not frame our website or any of its pages. You may only link to the home page of our website. You may not deep link (link to any other page) or link in any way that could suggest that we endorse or support you, or that you have any rights in our website or intellectual property, unless we have given you written permission to do so.</p>
                <h3 className="pt-5 font-bold">3.4 Virtual agents.</h3>
                <p>You may not use any technology (including spiders, crawlers, bots, and similar virtual agents) to search or gain any information from our platforms, unless we have given you permission to do so.</p>
            </div>
            <div className="md:max-w-3xl pt-10">
                <h2 className="text-3xl md:text-4xl text-black font-heading py-3">4. Capacity</h2>
                <p>You promise that you are entitled to visit this website and agree to the terms because you:
                    <ul className="list-disc ml-4 pb-5">
                        <li>
                            are at least 18 (or regarded as legally adult), and have the legal right and capacity to do so; or
                        </li>
                        <li>
                        are not 18 yet, but have permission from your parent (or legal guardian) to do so.
                        </li>
                    </ul>
                </p>
            </div>
            <div className="md:max-w-3xl">
                <h2 className="text-3xl md:text-4xl text-black font-heading py-3">5. Accurate information</h2>
                <p>You promise that you will only give accurate information to us and our platform.
                </p>
            </div>
            <div className="md:max-w-3xl pt-10">
                <h2 className="text-3xl md:text-4xl text-black font-heading py-3">6. Intellectual property</h2>
                <h3 className="font-bold">6.1 Ownership.</h3>
                <p>Except as provided to the contrary in the agreement, all rights, title, interest, and ownership (including all rights under all copyright, patent, and other intellectual property laws) in, to, or of this website are our sole property or will vest in us or a third party licensor. All moral rights are reserved.</p>
                <h3 className="pt-5 font-bold">6.2 Trademarks.</h3>
                <p> Our logo and sub-logos, marks, and trade names are our trademarks and no person may use them without our permission. Any other trademark or trade name that may appear on our marketing material is the property of its respective owner.</p>
                <h3 className="pt-5 font-bold">6.3 Restrictions.</h3>
                <p> Except as expressly permitted under the agreement, the website may not be:</p>
                <ul className="list-disc ml-4 pb-5">
                    <li>
                    modified or used to make derivative works;
                    </li>
                    <li>
                    rented, leased, loaned, sold or assigned;
                    </li>
                    <li>
                    reverse engineered or copied; or
                    </li>
                    <li>
                    reproduced or distributed.
                    </li>
                </ul>
            </div>
            <div className="md:max-w-3xl pt-10">
                <h2 className="text-3xl md:text-4xl text-black font-heading py-3">7. Limits to our liability</h2>
                <h3 className="font-bold">7.1 Own risk.</h3>
                <p>We provide the platform “as is”. We do not give any express or implied warranty or make any other promise about this platform. For example, we do not warrant that it is good quality, fit for any particular purpose, accurate, complete, up-to-date, legally effective or secure. We also do not warrant that it is free of latent defects, errors, malicious software or infringing content, or that you will have quiet or uninterrupted use of it.</p>
                <h3 className="pt-5 font-bold">7.2 Indemnity.</h3>
                <p>You indemnify (or promise to protect) us against any claim, demand, loss, damage, cost, or liability (including reasonable {"attorney's"} fees) related to your access to or use of this platform.</p>
                <h3 className="pt-5 font-bold">7.3 Faults.</h3>
                <p>We will do our best to fix any fault in this website as soon as reasonably practical after we discover it. This is the limit of our responsibility and liability for any fault in the website.</p>
                <h3 className="pt-5 font-bold">7.4 Direct damages limited.</h3>
                <p>If the previous clause does not apply for any reason, our maximum liability to you for all claims for direct damages is R100. This limit applies whether a claim is based on contract, delict (tort) or any other legal cause of action.</p>
                <h3 className="pt-5 font-bold">7.5 Indirect damages.</h3>
                <p>We will never be responsible for any indirect or consequential damages or losses, even if we should have foreseen them. These may include any loss of profit, loss of goodwill, loss of use or damages related to lost or damaged data.</p>
                <h3 className="pt-5 font-bold">7.6 Other websites.</h3>
                <p>We are not responsible for anyone {"else's"} website or mobile applications.</p>
            </div>
            <div className="md:max-w-3xl pt-10">
                <h2 className="text-3xl md:text-4xl text-black font-heading py-3">8. General</h2>
                <h3 className="font-bold">Entire agreement.</h3>
                <p>The terms are the entire agreement between the parties on the subject.</p>
                <h3 className="pt-5 font-bold">Changes to platform.</h3>
                <p>We may change or stop publishing this platform without notice and will not be responsible for any consequences.</p>
                <h3 className="pt-5 font-bold">Changes to terms.</h3>
                <p>We may change the terms at any time by placing a notice on this platform or updating this web page. If you do not agree with the change, you must stop using this website or the changed terms will apply to you.</p>
                <h3 className="pt-5 font-bold">Facts about platform.</h3>
                <p>If an authorised administrator of this platform signs a letter confirming any fact related to the platform, that letter is conclusive proof of its contents. These may include the version of the terms that apply to any dispute, or what content or functions the platform had at a particular time or date.</p>
                <h3 className="pt-5 font-bold">Waiver.</h3>
                <p>We never waive (give up) our rights, even if we allow you any favour or extension of time, or we delay enforcing our rights against you.</p>
                <h3 className="pt-5 font-bold">Severability.</h3>
                <p>Any term that is invalid, illegal, or cannot be enforced must be regarded as deleted. The remaining terms continue as intended.</p>
                <h3 className="pt-5 font-bold">Law and jurisdiction.</h3>
                <p>South African law and conditions (such as time and date) govern the terms. Only the South African courts may decide any dispute about the terms.</p>
            </div>
        </div>
      </section>
    </Layout>
  );
}

export default TermsPage;